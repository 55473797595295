import middle from 'data4-commons/middlewares/api/middle'
import {
    addReport as addReportApi,
    deleteReport as deleteReportApi
} from './api'
import { checkMedia } from 'data4-commons/api/media/api'
import configureCache from 'data4-commons/middlewares/api/cache'
import { kpiReportsDatacenterSelector } from './selectors'
import { DOMAIN_SHORTCUT } from 'data4-commons/global'
import { buildUrlWithParams } from 'data4-commons/entities/fetchEntities'

const prefix = 'KPI_REPORT/'

export const GET_KPI_REPORTS_DATACENTER_CONFIG = `${prefix}GET_KPI_REPORTS_DATACENTER_CONFIG`
export const GET_KPI_REPORTS_DATACENTER_CONFIG_LOADING = `${prefix}GET_KPI_REPORTS_DATACENTER_CONFIG_LOADING`
export const GET_KPI_REPORTS_DATACENTER_CONFIG_ERROR = `${prefix}GET_KPI_REPORTS_DATACENTER_CONFIG_ERROR`
export const GET_KPI_REPORTS_DATACENTER = `${prefix}GET_KPI_REPORTS_DATACENTER`
export const GET_KPI_REPORTS_CLIENT = `${prefix}GET_KPI_REPORTS_CLIENT`
export const GET_KPI_REPORT_DATA = `${prefix}GET_KPI_REPORTS_DATA`
export const ADD_KPI_REPORT_CLIENT = `${prefix}ADD_KPI_REPORT_CLIENT`
export const DELETE_KPI_REPORT_CLIENT = `${prefix}DELETE_KPI_REPORT_CLIENT`
export const CHECK_CALC_METHOD_DOC = `${prefix}CHECK_CALC_METHOD_DOC`

// TODO MISE EN CACHE AVEC HTTP (last-modified)
const cacheDataCenterReport = configureCache(60)

export const getKpiReportsDatacenter = () => middle(GET_KPI_REPORTS_DATACENTER, {
    api: '/api',
    url: '/kpi-report/datacenter',
    method: 'GET',
    cache: cacheDataCenterReport,
    selector: kpiReportsDatacenterSelector
})

export const getKpiReportsDatacenterConfig = () => middle(GET_KPI_REPORTS_DATACENTER_CONFIG, {
    api: '/api',
    url: '/kpi-report/datacenter-config',
    method: 'GET'
})

export const getKpiReportsClient = () => middle(GET_KPI_REPORTS_CLIENT, {
    api: '/api',
    url: '/kpi-report/client',
    method: 'GET'
})

export const getKpiReportData = (type, reportId, params = {}) => middle(GET_KPI_REPORT_DATA, {
    api: '/api',
    url: buildUrlWithParams(`/kpi-report/${type}/${reportId}/data`, params),
    method: 'GET'
})

export const addReport = (values) => (dispatch) => {
    return addReportApi(values)
        .then((report) => {
            dispatch({
                type: ADD_KPI_REPORT_CLIENT,
                payload: report
            })
            return report
        })
}

export const deleteReport = (reportId) => (dispatch) => {
    return deleteReportApi(reportId)
        .then(() => {
            dispatch({
                type: DELETE_KPI_REPORT_CLIENT,
                payload: reportId
            })
            return reportId
        })
}

export const checkCalcMethodDocument = (lang) => (dispatch) => {
    return checkMedia(DOMAIN_SHORTCUT.KPI_CALC_METHOD, lang)
        .then(() => {
            dispatch({
                type: CHECK_CALC_METHOD_DOC,
                payload: true
            })
        }, (e) => {
            dispatch({
                type: CHECK_CALC_METHOD_DOC,
                payload: false
            })
            throw e
        })
}

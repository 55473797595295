import React from 'react'
// lib
import { FormattedMessage } from 'react-intl'
import { reduxForm, Field } from 'redux-form'
// component
import { Button, Form } from 'reactstrap'
// internal components
import { TextField, Errors } from '../../../components/reduxForm'
import Headline from '../../../components/Headline'
// utils
import { validateMandatoryFields } from 'data4-commons/form/utils'

const validate = validateMandatoryFields(['code'])

const Check2FACodeForm = ({
    submitting,
    handleSubmit,
    error
}) => {
    return (
        <Form
            className="form-standard"
            onSubmit={handleSubmit}
        >
            <Headline
                label={<FormattedMessage id="login.2fa.title" />}
                type="secondary"
            />
            <p className="contenu">
                <FormattedMessage id="login.2fa.check_code.help_text" />
            </p>
            <Field
                name="code"
                component={TextField}
                size="full"
                autoComplete="off"
                autoFocus
            />
            <div className="form-validation">
                <Errors errors={error} />
                <Button
                    color="primary"
                    disabled={submitting}
                    type="submit"
                >
                    <FormattedMessage id="btn.connect" />
                </Button>
            </div>
        </Form>
    )
}

export default reduxForm({
    validate
})(Check2FACodeForm)
import React, { PureComponent, Fragment } from 'react'
// lib
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { compose } from 'recompose'
import { FormattedMessage, intlShape, injectIntl } from 'react-intl'
// components
import { Button, Form } from 'reactstrap'
// internal components
import { TextField, SingleCheckbox, Errors } from '../../../components/reduxForm'
import FormModal from '../../../components/dialog/FormModal'
import ForgotPasswordField, { validate as validateForgotPassword } from '../password/forgot/ForgotPasswordField'
// api
import { askToResetPassword } from '../password/api'
// utils
import { validateMandatoryFields } from 'data4-commons/form/utils'
import { toasterSuccess } from '../../../toaster/utils'
import handleError from 'data4-commons/form/handleError'

const validate = validateMandatoryFields(['login', 'password'])

class LoginForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            openForgotPwdDialog: false
        }
    }

    openForgotPwdDialog = () => {
        this.setState({
            openForgotPwdDialog: true
        })
    }
    closeForgotPwdDialog = () => {
        this.setState({
            openForgotPwdDialog: false
        })
    }

    handleResetPassword = (values) => {
        return askToResetPassword(values)
            .then(() => {
                this.closeForgotPwdDialog()
                toasterSuccess('login.forgot_password.success')
            }, handleError)
    }

    render() {
        const {
            intl,
            // redux-form
            error,
            submitting,
            handleSubmit
        } = this.props
        return (
            <Fragment>
                <Form
                    className="form-standard login-form"
                    onSubmit={handleSubmit}
                >
                    <Field
                        name="login"
                        component={TextField}
                        placeholder={intl.formatMessage({ id: 'login.identify.placeholder' })}
                        size="full"
                    />
                    <Field
                        name="password"
                        component={TextField}
                        type="password"
                        placeholder={intl.formatMessage({ id: 'login.password.placeholder' })}
                        size="full"
                    />
                    <Errors errors={error} />
                    <Field
                        name="remember-me"
                        component={SingleCheckbox}
                        label={<FormattedMessage id="login.stay_connected.label" />}
                    />
                    <div className="form-validation">
                        <div
                            className="password-forgotten"
                            onClick={this.openForgotPwdDialog}
                        >
                            <FormattedMessage id="login.forgot_password.label" />
                        </div>
                        <Button
                            className="btn-uppercase"
                            color="primary"
                            disabled={submitting}
                            type="submit"
                        >
                            <FormattedMessage id="btn.connect" />
                        </Button>
                    </div>
                </Form>
                <FormModal
                    open={this.state.openForgotPwdDialog}
                    title={<FormattedMessage id="login.forgot_password.dialog.title" />}
                    onClose={this.closeForgotPwdDialog}
                    submitLabel={<FormattedMessage id="btn.validate"/>}
                    size="xl"
                    form="ForgotPasswordForm"
                    validate={validateForgotPassword}
                    onSubmit={this.handleResetPassword}
                >
                    <ForgotPasswordField />
                </FormModal>
            </Fragment>
        )
    }
}

LoginForm.propTypes = {
    intl: intlShape.isRequired,
    // redux-form
    form: PropTypes.string.isRequired,
    error: PropTypes.any,
    handleSubmit: PropTypes.func
}

export default compose(
    reduxForm({
        validate
    }),
    injectIntl
)(LoginForm)

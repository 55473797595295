import { combineReducers } from 'redux'
import {
    GET_LIST_ACCESS,
    EXPORT_ACCESS_FINISH,
    EXPORT_ACCESS_START
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const list = createReducer(GET_LIST_ACCESS)

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_ACCESS_FINISH:
            return false
        case EXPORT_ACCESS_START:
            return true
        default:
            return state
    }
}

export default combineReducers({
    list,
    exporting
})

import { buildUrlWithParams, fetchFactory } from 'data4-commons/entities/fetchEntities'

export const getGroupsPreview = (sort) => fetchFactory(buildUrlWithParams('/groups/preview', { sort }), {}, '/api')

export const getGroups = () => fetchFactory('/groups', {}, '/api')

export const exportGroups = () => fetch('/api/groups/export', {
    headers: {
        Accept: 'application/octet-stream'
    }
})

export const createGroup = (values) => fetchFactory('/groups', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const updateGroup = (groupId, values) => fetchFactory(`/groups/${groupId}`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const deleteAndReassignGroup = (groupIdToDelete, values) => fetchFactory(`/groups/${groupIdToDelete}/replace`, {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const deleteGroup = (groupIdToDelete) => fetchFactory(`/groups/${groupIdToDelete}`, {
    method: 'DELETE'
}, '/api')
